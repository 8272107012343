<template>
  <div class="app-home">
    <div>
<!--      <SwiperTop/>-->
    </div>
    <div style="text-align: left">
<!--      <p style="display: block;font-size: 28px;font-weight: 600;margin: 5px 0 5px 200px"> {{$t('hint.3')}} </p>-->
<!--      <p style="display: block;font-size: 28px;font-weight: 600;"> {{$t('hint.3')}} </p>-->
    </div>
    <div style="width: 100%">
<!--      <SwiperBottom/>-->
    </div>
    <div class="GoodList">
      <AllGoodsList />
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
// import SwiperTop from '@/components/swipers/SwiperTop'
// import SwiperBottom from '@/components/swipers/SwiperBottom'
import AllGoodsList from "@/views/goods/AllGoodsList"
// import { localeLanguage } from '@/lang'

export default {
  name: 'Home',
  components: {
    AllGoodsList,
  },
  data() {
    return {
      list: [],
      loading: false,
      initDict: {
        // mimembercardtype: 'mimember.cardtype',
      }
    }
  },
  computed: {
    ...mapGetters([
      'userCode',
      'username',
      'isAgent',
      'cxFalg',
      'companyCode',
      'language'
    ]),
    mimembercardtype() {
      return this.$ll(this.initDict.mimembercardtype)
    },
  },
  created() {
    // this.getList()
  },
  mounted() {
    // TODO:首页跳转
    // if (window.location.href!=='https://www.whieda.org/#/') {
    //   window.location.href='https://www.whieda.org/#/'
    // }
    // localeLanguage()
  },
  methods: {
    // getList() {
    //   console.log('首页getlist')
    // },
    // parseNumber(val) {
    //   return num2string(val)
    // },
  }
}
</script>

<style lang="scss">

</style>
